/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery")

exports.onRouteUpdate = ({ location, prevLocation }) => {
  $(function () {
    // Add Overlay when Navbar dropdown click
    $("#asm-navbar .dropdown").on("show.bs.dropdown", function () {
      $(".asm-main").toggleClass("overlay")
    })

    $("#asm-navbar .dropdown").on("hide.bs.dropdown", function () {
      $(".asm-main").toggleClass("overlay")
    })

    // Toggle Light - Dark classes for Mobile Navbar
    $("#navbar-mobile .navbar-toggler").on("click", function () {
      $("#navbar-mobile").toggleClass(
        "navbar-dark navbar-light bg-black-alpha bg-white-alpha"
      )

      // Add Dark background to Navbar
      $("#asm-navbar").toggleClass("bg-black-alpha")

      // Hide remaining sections when Opened menu
      $(".asm-main").toggleClass("d-none")
      $("#asm-footer").toggleClass("d-none")
      $("#asm-contact").toggleClass("d-none")
    })

    // Enable DataTables: https://datatables.net/examples/basic_init/
    try {
      if ($.fn.dataTable.isDataTable(".table.table-hover")) {
        $(".table.table-hover").DataTable()
      } else {
        $(".table.table-hover").DataTable({
          language: {
            url:
              "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json",
          },
        })
      }
    } catch (error) {
      console.log(
        "Unable to add Filters to a table from this page - " + error.name
      )
    }

    if ($("#asm-template-video").length) {
      waitForEl(".asm-media-main", setMediaSidebarOverflow, 30)
    }

    if ($("#asm-page-general").length) {
      waitForEl("#asm-page-general", setMediaSidebarOverflow, 30)
      waitForEl("#asm-page-general", setAccordionTabsOverflow, 30)
    }
  })

  const setMediaSidebarOverflow = () => {
    $(".asm-media-main").each(function () {
      // Keep a reference to Media Container
      var mediaContainer = $(this)
      var mediaSingle = mediaContainer.find(".asm-media-single")
      var mediaSidebar = mediaContainer.find(".asm-media-sidebar")

      if (mediaSingle.length > 0 && mediaSidebar.length > 0) {
        var mediaCard = mediaSingle.children(".card")
        if (mediaSidebar.height() > mediaCard.height()) {
          mediaSidebar.css({
            "overflow-y": "scroll",
            "max-height": mediaCard.height(),
          })
        }
      }
    })
  }

  const setAccordionTabsOverflow = () => {
    $(".asm-accordion-lists-container").each(function () {
      // Keep a reference to Accordion Container
      var aContainer = $(this)
      var tabsWrapper = aContainer.children(".nav-tabs-wrapper")
      var navTabs = tabsWrapper.children(".nav-tabs")

      // Get .nav-item Width
      var navItemWidth = navTabs.children(".nav-item").width()

      // Get .nav-tabs Height
      var navTabsHeight = navTabs.height()

      // Set .nav-tabs-wrapper Height
      tabsWrapper.height(navTabsHeight)

      // Set .scroller marginTop according to .nav-tabs Height
      aContainer
        .children(".scroller")
        .css("margin-top", navTabsHeight / 2 - $(".scroller").outerHeight() / 2)

      // Get Width of list according to all .nav-items
      var widthOfList = function () {
        var itemsWidth = 0
        navTabs.children(".nav-item").each(function () {
          var itemWidth = $(this).outerWidth()
          itemsWidth += itemWidth
        })

        return itemsWidth
      }

      // Get Left position of .nav-tabs
      var getLeftPosi = function () {
        return navTabs.position().left
      }

      // Get Width of all hidden items
      var widthOfHidden = function () {
        return widthOfList() - tabsWrapper.outerWidth() + getLeftPosi()
      }

      // Re Adjust
      var reAdjust = function () {
        if (tabsWrapper.outerWidth() < widthOfList()) {
          aContainer.children(".scroller").fadeIn("slow")
        } else {
          aContainer.children(".scroller").hide()
        }
      }

      reAdjust()

      $(window).on("resize", function (e) {
        reAdjust()
      })

      // On .scroller-right Click
      aContainer.children(".scroller-right").click(function () {
        if (widthOfHidden() > 0) {
          navTabs.animate(
            { left: "-=" + navItemWidth + "px" },
            "slow",
            function () {}
          )
        } else {
        }
      })

      // On .scroller-right Left
      aContainer.children(".scroller-left").click(function () {
        if (getLeftPosi() < 0) {
          navTabs.animate(
            { left: "+=" + navItemWidth + "px" },
            "slow",
            function () {}
          )
        } else {
        }
      })
    })
  }

  const waitForEl = function (selector, callback, maxTimes = false) {
    if ($(selector).hasClass("asm-element-loaded")) {
      callback()
    } else {
      if (maxTimes === false || maxTimes > 0) {
        maxTimes !== false && maxTimes--
        setTimeout(function () {
          waitForEl(selector, callback, maxTimes)
        }, 100)
      }
    }
  }
}

exports.onClientEntry = () => {
  $(window).on("load", function () {
    // Show Modal on Start if 'enabled'
    if ($("#asm-homeModal").attr("enabled")) {
      $("#asm-homeModal").modal("show")
    }
  })
}
