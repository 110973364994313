// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audios-js": () => import("./../../../src/pages/audios.js" /* webpackChunkName: "component---src-pages-audios-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-fotos-js": () => import("./../../../src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-asmet-audio-js": () => import("./../../../src/templates/asmet-audio.js" /* webpackChunkName: "component---src-templates-asmet-audio-js" */),
  "component---src-templates-asmet-event-js": () => import("./../../../src/templates/asmet-event.js" /* webpackChunkName: "component---src-templates-asmet-event-js" */),
  "component---src-templates-asmet-notice-js": () => import("./../../../src/templates/asmet-notice.js" /* webpackChunkName: "component---src-templates-asmet-notice-js" */),
  "component---src-templates-asmet-photo-js": () => import("./../../../src/templates/asmet-photo.js" /* webpackChunkName: "component---src-templates-asmet-photo-js" */),
  "component---src-templates-asmet-video-js": () => import("./../../../src/templates/asmet-video.js" /* webpackChunkName: "component---src-templates-asmet-video-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-subcategory-js": () => import("./../../../src/templates/blog-subcategory.js" /* webpackChunkName: "component---src-templates-blog-subcategory-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-page-general-js": () => import("./../../../src/templates/page-general.js" /* webpackChunkName: "component---src-templates-page-general-js" */)
}

